<template>
  <div class="d-flex flex-column justify-center" style="width: 100%">
    <v-container class="d-flex flex-column" style="max-width: 1030px; margin-top: 50px;">
      <h2>LOVIISAN AITTA OSAKEYHTIÖ ASIAKASREKISTERIN TIETOSUOJASELOSTE</h2>
<p>Pvm: 8.5.2018</p>
<h3>1 REKISTERINPITÄJÄ</h3>
<p>Loviisan Aitta Oy, Y-tunnus 1947468-7, Vahdontie 600, 21290 Rusko.</p>
<h3>2 YHTEYSHENKILÖ REKISTEREITÄ KOSKEVISSA ASIOISSA</h3>
<p>Helena Aho,&nbsp;helena.aho@loviisanaitta.fi, puhelin 040&nbsp;584 8797.</p>
<h3>3 REKISTERIN NIMI</h3>
<p>Loviisan Aitan asiakasrekisteri kivijalkamyymälän kanta-asiakkaille ja verkkokaupan asiakkaille.</p>
<h3>4 HENKILÖTIETOJEN KÄSITTELYN TARKOITUS JA PERUSTE</h3>
<p>Henkilötietojen käsittelyn ensisijaisena perusteena on Loviisan Aitan asiakkaan ja Loviisan Aitan välinen asiakassuhde, asiakkaan suostumus, asiakkaan antama toimeksianto tai muu asiallinen yhteys.</p>
<p>Henkilötietoja voidaan käsitellä seuraavissa käyttötarkoituksissa:</p>
<p>Asiakassuhteen, asiakaspalvelun ja niihin liittyvän viestinnän ja markkinoinnin hoitaminen, toteuttaminen, kehittäminen ja seuranta.</p>
<p>Asiakassuhteiden analysointi, ryhmittely ja raportointi, kanta-asiakasohjelman toteuttaminen sekä muut kokonaisasiakkuuden ja Loviisan Aitan liiketoiminnan kehittämiseen liittyvät tarkoitukset.</p>
<p>Asiakaspalautteen ja asiakkaan tyytyväisyystietojen kerääminen ja käsittely.</p>
<p>Markkinatutkimusten ja mielipidekyselyiden toteuttaminen.</p>
<p>Tämän tietosuojaselosteen kohdassa 10 tarkemmin kuvatut profilointitarkoitukset.</p>
<p>Käsittelytehtäviä voidaan ulkoistaa Loviisan Aitalle ja/tai ulkopuolisille palveluntarjoajille tietosuojalainsäädännön mukaisesti ja sen asettamissa rajoissa.</p>
<h3>5 REKISTERIN TIETOSISÄLTÖ</h3>
<p>Rekisteröidystä voidaan tallentaa muun muassa seuraavankaltaisia tietoja:</p>
<p>Nimi, kutsumanimi, asiakasnumero, sukupuoli, kieli, osoite, puhelinnumero, sähköposti-osoite ja muut tarpeelliset yhteystiedot.</p>
<p>Asumistiedot, talouden koko.</p>
<p>Palvelujen käyttö- ja ostotiedot, kulloinkin voimassa olevan kanta-asiakkuuden etuohjelman taso ja voimassaoloaika, sekä markkinoinnin ja viestinnän toteutustiedot eri asiointikanavissa, kuten verkkopalveluissa ja automaattipalvelut.</p>
<p>Rekisteröidyn itse tuottama sisältö kuten asiakaspalaute sekä hänen itsestään antamat lisätiedot kuten asiakkuuteen liittyvät toiveet, tyytyväisyystiedot, kiinnostuksen kohteet, harrastustiedot tai muut vastaavat tiedot.</p>
<p>Rekisteröidyn toivomia ja käyttämiä palveluita maksutietoineen.</p>
<p>Muita asiakkuuteen liittyviä tietoja, kuten esimerkiksi asiakkaaseen yhdistettävissä oleva verkkosivujen käytöstä kerättävä tieto, kuten esimerkiksi käyttäjän IP-osoite, käynnin kellonaika, vieraillut sivut, käytet-ty selaintyyppi (esim. Internet Exproler, Firefox), verkko-osoite, josta käyttäjä on tullut verkkosivulle sekä palvelin, jolta käyttäjä on tullut verkkosivulle.</p>
<p>Tunnistamis- ja varmentamisvälineiden ja -palveluiden käyttöön liittyviä tarpeellisia tietoja.</p>
<p>Tiedon käsittelyyn liittyviä tietoja, kuten tallennuspäivämäärä ja tietolähde.</p>
<h3>6 HENKILÖTIETOJEN SÄILYTYSAIKA</h3>
<p>Loviisan Aitta säilyttää henkilötietoja asiakasrekisterissä kunnes rekisteröidyn ja Loviisan Aitan välisen asiakassuhteen voidaan katsoa päättyneen. Päättymisaika määritetään rekisteröidyn viimeisimmästä palvelukontaktista Loviisan Aitan keskeisten liiketoimintalukujen perusteella.</p>
<h3>7 SÄÄNNÖNMUKAISET TIETOLÄHTEET</h3>
<p>Tietoja saadaan ensisijaisesti seuraavista lähteistä:</p>
<p>Rekisteröity itse ja rekisteröidyn asiakkuuteen, palveluiden käyttöön, viestintään sekä asiointiin liittyvät tapahtumat.</p>
<p>Tunnistamis-, varmennus-, osoite-, päivitys-, luottotieto- tai muuta vastaavaa palvelua tarjoava osapuoli.</p>
<p>Väestörekisterikeskuksen väestötietojärjestelmä, ja muut tunnetut järjestelmät.</p>
<h3>8 TIETOJEN SÄÄNNÖNMUKAISET LUOVUTUKSET JA TIETOJEN SIIRTO EUROOPAN UNIONIN TAI EUROOPAN TALOUSALUEEN ULKOPUOLELLE</h3>
<p>Tietoja luovutetaan Loviisan Aitan tämän tietosuojaselosteen kohdassa 4 kuvattuihin käyttötarkoituksiin sekä, Loviisan Aitan suoramarkkinointirekisteriin kuitenkin aina tietosuojalainsäädännön mukaisesti ja sen asettamissa rajoissa.</p>
<p>Asiakastietoja ei luovuteta Loviisan Aitan tai Loviisan Aitan lukuun toimivien palveluiden ja viestinnän tuotantoon, kehittämiseen tai ylläpitoon osallistuvien tahojen ulkopuolelle muuten kuin sopimuksen, erillisen suostumuksen ja/tai nimenomaisten säädösten mukaan.</p>
<p>Asiakastietoja voidaan siirtää Euroopan unionin tai Euroopan talousalueen ulkopuolelle, muun muassa Yhdysvaltoihin tietosuojalainsäädännön mukaisesti ja sen asettamissa rajoissa.</p>
<h3>9 KUVAUS REKISTERIN SUOJAUKSEN PERIAATTEISTA</h3>
<p>Mahdollinen manuaalinen aineisto säilytetään lukitussa tilassa, jonne on pääsy vain erikseen oikeuden saaneilla henkilöillä. Digitaaliseen aineistoon on pääsy vain siihen oikeutetun työntekijän, ammatinharjoittajan tai yhteistyökumppanin henkilökohtaisella käyttäjätunnuksella ja salasanalla. Käyttöoikeuksia on eritasoisia ja kullekin käyttäjälle annetaan tehtävän hoitamisen kannalta riittävä, mutta mahdollisimman suppea käyttöoikeus.</p>
<h3>10 PROFILOINTI</h3>
<p>Osana asiakasrekisteriin tallennettujen henkilötietojen käsittelytoimia Loviisan Aitta voi hyödyntää tietoja myös profilointitarkoituksissa. Profilointi toteutetaan luomalla rekisteröidylle asiakastunniste, jonka avulla erilaisia palvelun käyttämisen yhteydessä syntyviä rekisteröityä koskevia tietoja voidaan yhdistellä. Edellä kuvatun kaltaisesti luotua profiilia voidaan tämän jälkeen esimerkiksi verrata muista rekisteröidyistä luotuihin profiileihin.</p>
<p>Profiloinnin tarkoituksena on selvittää palveluiden kysyntää ja asiakaskäyttäytymistä.</p>
<h3>11 REKISTERÖIDYN OIKEUS VASTUSTAA HENKILÖTIETOJEN KÄSITTELYÄ JA SUORAMARKKINOINTIA (KIELTO-OIKEUS)</h3>
<p>Rekisteröidyllä on oikeus henkilökohtaiseen erityiseen tilanteeseensa liittyen vastustaa itseensä kohdistuvaa profilointia ja muita käsittelytoimia, joita Loviisan Aitta kohdistaa rekisteröidyn henkilötietoihin siltä osin, kun tietojen käsittelemisen perusteena on Loviisan Aitan ja rekisteröidyn välinen asiakassuhde.&nbsp; Rekisteröity voi esittää vastustamista koskevan vaatimuksensa tämän tietosuojaselosteen kohdan 13 mukaisesi. Rekisteröidyn tulee vaatimuksen yhteydessä yksilöidä se erityinen tilanne, johon perustuen hän vastustaa käsittelyä. Loviisan Aitta voi kieltäytyä toteuttamasta vastustamista koskevaa pyyntöä laissa säädetyin perustein.</p>
<p>Rekisteröity voi antaa Loviisan Aitalle suoramarkkinointia koskevia suostumuksia tai kieltoja kanavakohtaisesti mukaan lukien suoramarkkinointitarkoituksiin tapahtuva profilointi.</p>
<h3>12 MUUT HENKILÖTIETOJEN KÄSITTELYYN LIITTYVÄT REKISTERÖIDYN OIKEUDET</h3>
<p><strong>12.1 Rekisteröidyn oikeus saada pääsy tietoihin (tarkastusoikeus)</strong><br>
Rekisteröidyllä on oikeus tarkastaa, mitä häntä koskevia tietoja Loviisan Aitan asiakasrekisteriin on talletettu. Tarkastuspyyntö tulee tehdä tämän tietosuojaselosteen kohdan 13 mukaisesti. Tarkastusoikeus voidaan evätä laissa säädetyin perustein. Tarkastusoikeuden käyttäminen on lähtökohtaisesti maksutonta.</p>
<p><strong>12.2 Rekisteröidyn oikeus vaatia tiedon oikaisemista, poistamista tai käsittelyn rajoittamista</strong><br>
Jos asiakas on myös . Siltä osin kuin rekisteröity tai käyttäjä voi toimia itse, hänen on ilman aiheetonta viivytystä, saatuaan tiedon virheestä tai, virheen itse havaittuaan, oma-aloitteisesti oikaistava, poistettava tai täydennettävä rekisterissä oleva virheellinen, tarpeeton, puutteellinen tai vanhentunut tieto.</p>
<p>Siltä osin kuin rekisteröity ei pysty korjaamaan tietoja itse, korjauspyyntö tehdään tämän tietosuojaselosteen kohdan 13 mukaisesti.</p>
<p>Rekisteröidyllä on myös oikeus vaatia rekisterinpitäjää rajoittamaan henkilötietojensa käsittelyä esimerkiksi siinä tilanteessa, kun rekisteröity odottaa Loviisan Aitan vastausta tietojensa oikaisemista tai poistamista koskevaan pyyntöön.</p>
<p><strong>12.3 Rekisteröidyn oikeus siirtää tiedot järjestelmästä toiseen</strong><br>
Siltä osin kun rekisteröity on itse toimittanut asiakasrekisteriin tietoja, joita käsitellään rekisteröidyn antaman suostumuksen tai toimeksiannon nojalla, rekisteröidyllä on oikeus saada tällaiset tiedot itselleen pääsääntöisesti koneluettavassa muodossa ja oikeus siirtää nämä tiedot toiselle rekisterinpitäjälle.</p>
<p><strong>12.4 Rekisteröidyn oikeus tehdä valitus valvontaviranomaiselle</strong><br>
Rekisteröidyllä on oikeus tehdä valitus toimivaltaiselle valvontaviranomaiselle, jos rekisterinpitäjä ei ole noudattanut toiminnassaan soveltuvaa tietosuojasääntelyä.</p>
<p><strong>12.5 Muut oikeudet</strong><br>
Mikäli henkilötietoja käsitellään rekisteröidyn suostumukseen perustuen, rekisteröidyllä on oikeus peruuttaa suostumuksensa ilmoittamalla tästä Loviisan Aitalle tämän tietosuojaselosteen kohdan 13 mukaisesti.</p>
<h3>13 YHTEYDENOTOT</h3>
<p>Kaikissa henkilötietojen käsittelyyn liittyvissä kysymyksissä ja omien oikeuksien käyttämiseen liittyvissä tilanteissa rekisteröidyn tulee ottaa yhteyttä Loviisan Aittaan Loviisan Aitan myymälässä tai postitse osoitteeseen: Loviisan Aitta, Vahdontie 600, 21290 Rusko. Loviisan Aitta voi tarvittaessa pyytää rekisteröityä täsmentämään pyyntöään kirjallisesti ja rekisteröidyn henkilöllisyys voidaan tarpeen vaatiessa varmentaa ennen muihin toimenpiteisiin ryhtymistä.</p>
<h2>LOVIISAN AITTA OSAKEYHTIÖ ASIAKASKIRJEEN TIETOSUOJASELOSTE</h2>
<p>Pvm: 8.5.2018</p>
<h3>1 REKISTERINPITÄJÄ</h3>
<p>Loviisan Aitta Oy, Y-tunnus 1947468-7, Vahdontie 600, 21290 Rusko.</p>
<h3>2 YHTEYSHENKILÖ REKISTEREITÄ KOSKEVISSA ASIOISSA</h3>
<p>Helena Aho,&nbsp;helena.aho@loviisanaitta.fi, puhelin 040&nbsp;584 8797.</p>
<h3>3 REKISTERIN NIMI</h3>
<p>Loviisan Aitan asiakasrekisteri uutiskirjeen tilanneille.</p>
<h3>4 HENKILÖTIETOJEN KÄSITTELYN TARKOITUS JA PERUSTE</h3>
<p>Henkilötietojen käsittelyn ensisijaisena perusteena on Loviisan Aitan asiakkaan ja Loviisan Aitan välinen asiakassuhde, asiakkaan suostumus, asiakkaan antama toimeksianto tai muu asiallinen yhteys.</p>
<p>Henkilötietoja voidaan käsitellä seuraavissa käyttötarkoituksissa:</p>
<p>Asiakassuhteen, asiakaspalvelun ja niihin liittyvän viestinnän ja markkinoinnin hoitaminen, toteuttaminen, kehittäminen ja seuranta.</p>
<p>Asiakassuhteiden analysointi, ryhmittely ja raportointi, kanta-asiakasohjelman toteuttaminen sekä muut kokonaisasiakkuuden ja Loviisan Aitan liiketoiminnan kehittämiseen liittyvät tarkoitukset.</p>
<p>Asiakaspalautteen ja asiakkaan tyytyväisyystietojen kerääminen ja käsittely.</p>
<p>Markkinatutkimusten ja mielipidekyselyiden toteuttaminen.</p>
<p>Tämän tietosuojaselosteen kohdassa 10 tarkemmin kuvatut profilointitarkoitukset.</p>
<p>Käsittelytehtäviä voidaan ulkoistaa Loviisan Aitalle ja/tai ulkopuolisille palveluntarjoajille tietosuojalainsäädännön mukaisesti ja sen asettamissa rajoissa.</p>
<h3>5 REKISTERIN TIETOSISÄLTÖ</h3>
<p>Rekisteröidystä voidaan tallentaa muun muassa seuraavankaltaisia tietoja:</p>
<p>Nimi, kutsumanimi, asiakasnumero, sukupuoli, kieli, osoite, puhelinnumero, sähköposti-osoite ja muut tarpeelliset yhteystiedot.</p>
<p>Asumistiedot, talouden koko.</p>
<p>Palvelujen käyttö- ja ostotiedot, kulloinkin voimassa olevan kanta-asiakkuuden etuohjelman taso ja voimassaoloaika, sekä markkinoinnin ja viestinnän toteutustiedot eri asiointikanavissa, kuten verkkopalveluissa ja automaattipalvelut.</p>
<p>Rekisteröidyn itse tuottama sisältö kuten asiakaspalaute sekä hänen itsestään antamat lisätiedot kuten asiakkuuteen liittyvät toiveet, tyytyväisyystiedot, kiinnostuksen kohteet, harrastustiedot tai muut vastaavat tiedot.</p>
<p>Rekisteröidyn toivomia ja käyttämiä palveluita maksutietoineen.</p>
<p>Muita asiakkuuteen liittyviä tietoja, kuten esimerkiksi asiakkaaseen yhdistettävissä oleva verkkosivujen käytöstä kerättävä tieto, kuten esimerkiksi käyttäjän IP-osoite, käynnin kellonaika, vieraillut sivut, käytet-ty selaintyyppi (esim. Internet Exproler, Firefox), verkko-osoite, josta käyttäjä on tullut verkkosivulle sekä palvelin, jolta käyttäjä on tullut verkkosivulle.</p>
<p>Tunnistamis- ja varmentamisvälineiden ja -palveluiden käyttöön liittyviä tarpeellisia tietoja.</p>
<p>Tiedon käsittelyyn liittyviä tietoja, kuten tallennuspäivämäärä ja tietolähde.</p>
<h3>6 HENKILÖTIETOJEN SÄILYTYSAIKA</h3>
<p>Loviisan Aitta säilyttää henkilötietoja asiakasrekisterissä kunnes rekisteröidyn ja Loviisan Aitan välisen asiakassuhteen voidaan katsoa päättyneen. Päättymisaika määritetään rekisteröidyn viimeisimmästä palvelukontaktista Loviisan Aitan keskeisten liiketoimintalukujen perusteella.</p>
<h3>7 SÄÄNNÖNMUKAISET TIETOLÄHTEET</h3>
<p>Tietoja saadaan ensisijaisesti seuraavista lähteistä:</p>
<p>Rekisteröity itse ja rekisteröidyn asiakkuuteen, palveluiden käyttöön, viestintään sekä asiointiin liittyvät tapahtumat.</p>
<p>Tunnistamis-, varmennus-, osoite-, päivitys-, luottotieto- tai muuta vastaavaa palvelua tarjoava osapuoli.</p>
<p>Väestörekisterikeskuksen väestötietojärjestelmä, ja muut tunnetut järjestelmät.</p>
<h3>8 TIETOJEN SÄÄNNÖNMUKAISET LUOVUTUKSET JA TIETOJEN SIIRTO EUROOPAN UNIONIN TAI EUROOPAN TALOUSALUEEN ULKOPUOLELLE</h3>
<p>Tietoja luovutetaan Loviisan Aitan tämän tietosuojaselosteen kohdassa 4 kuvattuihin käyttötarkoituksiin sekä, Loviisan Aitan suoramarkkinointirekisteriin kuitenkin aina tietosuojalainsäädännön mukaisesti ja sen asettamissa rajoissa.</p>
<p>Asiakastietoja ei luovuteta Loviisan Aitan tai Loviisan Aitan lukuun toimivien palveluiden ja viestinnän tuotantoon, kehittämiseen tai ylläpitoon osallistuvien tahojen ulkopuolelle muuten kuin sopimuksen, erillisen suostumuksen ja/tai nimenomaisten säädösten mukaan.</p>
<p>Asiakastietoja voidaan siirtää Euroopan unionin tai Euroopan talousalueen ulkopuolelle, muun muassa Yhdysvaltoihin tietosuojalainsäädännön mukaisesti ja sen asettamissa rajoissa.</p>
<h3>9 KUVAUS REKISTERIN SUOJAUKSEN PERIAATTEISTA</h3>
<p>Mahdollinen manuaalinen aineisto säilytetään lukitussa tilassa, jonne on pääsy vain erikseen oikeuden saaneilla henkilöillä. Digitaaliseen aineistoon on pääsy vain siihen oikeutetun työntekijän, ammatinharjoittajan tai yhteistyökumppanin henkilökohtaisella käyttäjätunnuksella ja salasanalla. Käyttöoikeuksia on eritasoisia ja kullekin käyttäjälle annetaan tehtävän hoitamisen kannalta riittävä, mutta mahdollisimman suppea käyttöoikeus.</p>
<h3>10 PROFILOINTI</h3>
<p>Osana asiakasrekisteriin tallennettujen henkilötietojen käsittelytoimia Loviisan Aitta voi hyödyntää tietoja myös profilointitarkoituksissa. Profilointi toteutetaan luomalla rekisteröidylle asiakastunniste, jonka avulla erilaisia palvelun käyttämisen yhteydessä syntyviä rekisteröityä koskevia tietoja voidaan yhdistellä. Edellä kuvatun kaltaisesti luotua profiilia voidaan tämän jälkeen esimerkiksi verrata muista rekisteröidyistä luotuihin profiileihin.</p>
<p>Profiloinnin tarkoituksena on selvittää palveluiden kysyntää ja asiakaskäyttäytymistä.</p>
<h3>11 REKISTERÖIDYN OIKEUS VASTUSTAA HENKILÖTIETOJEN KÄSITTELYÄ JA SUORAMARKKINOINTIA (KIELTO-OIKEUS)</h3>
<p>Rekisteröidyllä on oikeus henkilökohtaiseen erityiseen tilanteeseensa liittyen vastustaa itseensä kohdistuvaa profilointia ja muita käsittelytoimia, joita Loviisan Aitta kohdistaa rekisteröidyn henkilötietoihin siltä osin, kun tietojen käsittelemisen perusteena on Loviisan Aitan ja rekisteröidyn välinen asiakassuhde.&nbsp; Rekisteröity voi esittää vastustamista koskevan vaatimuksensa tämän tietosuojaselosteen kohdan 13 mukaisesi. Rekisteröidyn tulee vaatimuksen yhteydessä yksilöidä se erityinen tilanne, johon perustuen hän vastustaa käsittelyä. Loviisan Aitta voi kieltäytyä toteuttamasta vastustamista koskevaa pyyntöä laissa säädetyin perustein.</p>
<p>Rekisteröity voi antaa Loviisan Aitalle suoramarkkinointia koskevia suostumuksia tai kieltoja kanavakohtaisesti mukaan lukien suoramarkkinointitarkoituksiin tapahtuva profilointi.</p>
<h3>12 MUUT HENKILÖTIETOJEN KÄSITTELYYN LIITTYVÄT REKISTERÖIDYN OIKEUDET</h3>
<p><strong>12.1 Rekisteröidyn oikeus saada pääsy tietoihin (tarkastusoikeus)</strong><br>
Rekisteröidyllä on oikeus tarkastaa, mitä häntä koskevia tietoja Loviisan Aitan asiakasrekisteriin on talletettu. Tarkastuspyyntö tulee tehdä tämän tietosuojaselosteen kohdan 13 mukaisesti. Tarkastusoikeus voidaan evätä laissa säädetyin perustein. Tarkastusoikeuden käyttäminen on lähtökohtaisesti maksutonta.</p>
<p><strong>12.2 Rekisteröidyn oikeus vaatia tiedon oikaisemista, poistamista tai käsittelyn rajoittamista</strong><br>
Jos asiakas on myös . Siltä osin kuin rekisteröity tai käyttäjä voi toimia itse, hänen on ilman aiheetonta viivytystä, saatuaan tiedon virheestä tai, virheen itse havaittuaan, oma-aloitteisesti oikaistava, poistettava tai täydennettävä rekisterissä oleva virheellinen, tarpeeton, puutteellinen tai vanhentunut tieto.</p>
<p>Siltä osin kuin rekisteröity ei pysty korjaamaan tietoja itse, korjauspyyntö tehdään tämän tietosuojaselosteen kohdan 13 mukaisesti.</p>
<p>Rekisteröidyllä on myös oikeus vaatia rekisterinpitäjää rajoittamaan henkilötietojensa käsittelyä esimerkiksi siinä tilanteessa, kun rekisteröity odottaa Loviisan Aitan vastausta tietojensa oikaisemista tai poistamista koskevaan pyyntöön.</p>
<p><strong>12.3 Rekisteröidyn oikeus siirtää tiedot järjestelmästä toiseen</strong><br>
Siltä osin kun rekisteröity on itse toimittanut asiakasrekisteriin tietoja, joita käsitellään rekisteröidyn antaman suostumuksen tai toimeksiannon nojalla, rekisteröidyllä on oikeus saada tällaiset tiedot itselleen pääsääntöisesti koneluettavassa muodossa ja oikeus siirtää nämä tiedot toiselle rekisterinpitäjälle.</p>
<p><strong>12.4 Rekisteröidyn oikeus tehdä valitus valvontaviranomaiselle</strong><br>
Rekisteröidyllä on oikeus tehdä valitus toimivaltaiselle valvontaviranomaiselle, jos rekisterinpitäjä ei ole noudattanut toiminnassaan soveltuvaa tietosuojasääntelyä.</p>
<p><strong>12.5 Muut oikeudet</strong><br>
Mikäli henkilötietoja käsitellään rekisteröidyn suostumukseen perustuen, rekisteröidyllä on oikeus peruuttaa suostumuksensa ilmoittamalla tästä Loviisan Aitalle tämän tietosuojaselosteen kohdan 13 mukaisesti.</p>
<h3>13 YHTEYDENOTOT</h3>
<p>Kaikissa henkilötietojen käsittelyyn liittyvissä kysymyksissä ja omien oikeuksien käyttämiseen liittyvissä tilanteissa rekisteröidyn tulee ottaa yhteyttä Loviisan Aittaan Loviisan Aitan myymälässä tai postitse osoitteeseen: Loviisan Aitta, Vahdontie 600, 21290 Rusko. Loviisan Aitta voi tarvittaessa pyytää rekisteröityä täsmentämään pyyntöään kirjallisesti ja rekisteröidyn henkilöllisyys voidaan tarpeen vaatiessa varmentaa ennen muihin toimenpiteisiin ryhtymistä.</p>
    </v-container>
    <foo-ter />
  </div>
</template>

<script>
import template from "../data/aboutus.json";
export default {
  data() {
    return {
      contentTemplate: template.template.content,
    };
  },
  created: function () {
    if (template.template.data) {
      this.$store.commit("updateCMSData", template.template.data);
    }
  },
};
</script>

<style scoped>


h2, h3 {
    font-family: 'Lora', serif;
    font-weight: 700;
}

@media screen and (max-width: 600px) {
  .life-style-content,
  .family {
    flex-direction: column;
    align-items: center;
  }

  .collection {
    flex-direction: column-reverse;
    align-items: center;
  }

  .life-style-content .v-image {
    margin: 0 auto 32px 0 !important;
  }

  .container .v-image {
    width: 100% !important;
    max-width: 100% !important;
  }
}
</style>